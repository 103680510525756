import LandingPageTextContent from "./LandingPageTextConten.jsx"

export default function LandingPage() {
    return (
        <div className="LandingPage relative  w-full flex flex-col  justify-center" id="home">
            <LandingPageTextContent />
            <div className="Background1  bg-gray-800 rounded-3xl"></div>
            <div className="Background2  bg-cyan-900 rounded-3xl"></div>
        </div>
    )
}