export default function ReadMoreBtn({ BlogLink }) {
    return (
        <a class="px-6 py-1  text-black group
                    w-fit inline-flex items-center gap-x-1 rounded-full 
                    bg-orange-500 hover:bg-orange-600
                    shadow-sm shadow-gray-900/50
                    hover:shadow-md hover:shadow-gray-900
                    transition ease-in-out duration-300 "
            href={BlogLink}
            target="_blank"
        >Read more
            <svg xmlns="http://www.w3.org/2000/svg" height="20px" className="borde mt-1" viewBox="0 -960 960 960" width="20px" fill="#000000"><path d="M480-160q-48-38-104-59t-116-21q-42 0-82.5 11T100-198q-21 11-40.5-1T40-234v-482q0-11 5.5-21T62-752q46-24 96-36t102-12q58 0 113.5 15T480-740v484q51-32 107-48t113-16q36 0 70.5 6t69.5 18v-480q15 5 29.5 10.5T898-752q11 5 16.5 15t5.5 21v482q0 23-19.5 35t-40.5 1q-37-20-77.5-31T700-240q-60 0-116 21t-104 59Zm80-200v-380l200-200v400L560-360Zm-160 65v-396q-33-14-68.5-21.5T260-720q-37 0-72 7t-68 21v397q35-13 69.5-19t70.5-6q36 0 70.5 6t69.5 19Zm0 0v-396 396Z" /></svg>
        </a>
    )
}