import LoginToDashboardbtn from '../CommonComponent/Buttons/LoginToDashboardBtn.jsx'
import Landing_page_WB from './LandingPageImages/Landing_page_WB.png'

export default function LandingPageTextContent() {
    return (
        <div className="flex justify-start xl:pl-20 lg:pl-16 pl-0 items-start">
            <div className="LandingPageTextContent flex flex-col 
                        px-8  
                        xl:w-1/2 lg:w-1/2 w-full">

                <div className=" h-64 w-full flex justify-center items-center xl:hidden lg:hidden md:flex flex">
                    <img src={Landing_page_WB} className=" h-full w-64 object-cover" alt="illustration"></img>
                </div>

                <h1 className="Heading text-7xl py-4 xl:text-start lg:text-start text-center">
                    ATRA-RMS
                </h1>

                <p className=" text-white py-4 text-lg font-semibold xl:text-start lg:text-start text-center">
                    ATRA-RMS is a dedicated component of the Atra IoT platform,
                    designed to streamline the management and monitoring of IoT devices.
                </p>

                <div className="flex xl:justify-start lg:justify-start  justify-center">
                    <LoginToDashboardbtn />
                </div>

            </div>
            <div className=" LandingPageImage flex justify-center items-center xl:mr-20 xl:flex lg:flex  hidden">
                <img src={Landing_page_WB} className=" xl:h-full xl:w-full lg:h-96 lg:w-96 object-cover" alt="illustration"></img>
            </div>
        </div>

    )
}