import ExploreMoreBtn from "../CommonComponent/Buttons/ExploreMoreBtn.jsx"


// export default function SupportedDevicesSmallCard({ DeviceName, DeviceType, DeviceImage, DeviceLink }) {
//     return (
//         // <div className="DeviceSmallCard relative w-96 h-80 rounded-3xl  flex justify-center items-center">
//         <div className="DeviceSmallCard relative rounded-3xl flex justify-center items-center">
//             <div className="bg-local bg-contain bg-center bg-no-repeat" style={{
//                 backgroundImage: `url(${DeviceImage})`
//             }}>
//                 <div className="absolute text-white">
//                     <h5 className="text-5xl">
//                         {DeviceName}
//                     </h5>
//                     <p className="text-md">
//                         {DeviceType}
//                     </p>
//                 </div>
//                 {/* <div className="absolute bottom-4 left-4"> */}
//                 <div className="absolute">
//                     <ExploreMoreBtn DeviceLink={DeviceLink} />
//                 </div>
//             </div>
//             {/* <img src={DeviceImage} className="hover:scale-105 transition ease-in-out duration-300" alt="gateway" /> */}
//             {/* <div className="absolute top-2 left-4 text-white"> */}

//         </div>
//     )
// }

export default function SupportedDevicesSmallCard({ DeviceName, DeviceType, DeviceImage, DeviceLink }) {
    return (
        <div className="max-w-sm mx-auto  DeviceSmallCard rounded-lg shadow-lg overflow-hidden">
            <div className="relative">
                <img
                    src={DeviceImage}
                    alt={DeviceName}
                    className="w-full h-80 object-cover object-top"
                // style={{ mixBlendMode: 'multiply' }} // This ensures the image blends well with the gradient
                />
                <div className="absolute inset-0 flex flex-col justify-end p-4 text-white">
                    <h2 className="text-xl font-semibold mb-1">{DeviceName}</h2>
                    <p className="text-sm mb-4">{DeviceType}</p>

                    <ExploreMoreBtn DeviceLink={DeviceLink} />
                </div>
            </div>
        </div>
    )
}