import SupportedDevicesBigCard from "./SupportedDevicesBigCard.jsx";
import SupportedDevicesSmallCard from "./SupportedDevicesSmallCard.jsx";
export default function SupportedDevicesCardCollection() {
    return (
        <div className="DevicesCardCollection flex justify-center flex-wrap xl:grid xl:grid-rows-2 xl:grid-flow-col ">

            <div className="row-span-2 xl:my-0 my-4 xl:m-0 m-4">
                <SupportedDevicesBigCard />
            </div>

            <div className="xl:ml-4 xl:my-0 my-4 xl:m-0 m-4 ">
                <SupportedDevicesSmallCard
                    DeviceName={"AG-702"}
                    DeviceType={"IoT gateway"}
                    DeviceImage={"https://atreyo.in/sites/default/files/a-gallery-products/AG-702-01.png"}
                    DeviceLink={"https://www.atreyo.in/en/products/iot-gateways/openwrt-lte-gateway-rs-485-rs232-AG-702"}
                />
            </div>
            <div className="xl:ml-4 xl:mt-2 mt-4 xl:my-0 my-4 xl:m-0 m-4">
                <SupportedDevicesSmallCard
                    DeviceName={"AG-1621"}
                    DeviceType={"Compact industrial computer"}
                    DeviceImage={"https://atreyo.in/sites/default/files/a-gallery-products/AG-1621-01.png"}
                    DeviceLink={"https://www.atreyo.in/en/products/computers-gateways/industrial-computer-gateway-AG-1621"}
                />
            </div>
            <div className="xl:ml-4 xl:my-0 my-4 xl:m-0 m-4">
                <SupportedDevicesSmallCard
                    DeviceName={"AG-1708"}
                    DeviceType={"Industrial Computer"}
                    DeviceImage={"https://atreyo.in/sites/default/files/a-gallery-products/AIC-1708-01.png"}
                    DeviceLink={"https://www.atreyo.in/en/products/computers-gateways/industrial-computer-AIC-1708"}
                />
            </div>
            <div className="xl:ml-4 xl:mt-2 mt-4 xl:my-0 my-4 xl:m-0 m-4">
                <SupportedDevicesSmallCard
                    DeviceName={"AG-1720"}
                    DeviceType={"Industrial Computer"}
                    DeviceImage={"https://atreyo.in/sites/default/files/a-gallery-products/AIC-1720-01.png"}
                    DeviceLink={"https://www.atreyo.in/en/products/computers-gateways/industrial-computer-AIC-1720"}
                />
            </div>
        </div>
    )
}