import FeaturesCardLtoR from "./FeaturesCardLtoR.jsx"
import FeaturesCardRtoL from "./FeaturesCardRtoL.jsx"

import remote_config_image_2 from './FeaturesImages/remote_config_image_2.png'
import security_image from './FeaturesImages/security_image.png'
import scalability_image from './FeaturesImages/scalability_image.png'
import reporting_analytics_image from './FeaturesImages/reporting_analytics_image.png'
import gps_monitoring_image from './FeaturesImages/gps_monitoring_image.png'
import network_monitoring_image from './FeaturesImages/network_monitoring_image.png'
import health_monitoring_image from './FeaturesImages/health_monitoring_image.png'



export default function FeatureCardCollection() {
    return (
        <div className="flex flex-col justify-center items-center">
            <FeaturesCardLtoR
                featureName={"Health Monitoring"}
                featureL1={"ATRA-RMS continuously monitors the health status of IoT devices in real-time, tracking various parameters such as CPU usage, memory utilization, network connectivity, and device temperature."}
                featureImage={health_monitoring_image}
            />
            <FeaturesCardRtoL
                featureName={"GPS Monitoring"}
                featureL1={"The IoT Platform tracks critical GPS data, including position, elevation, direction, and speed, among other key metrics."}
                featureL2={"This data, gathered from IoT devices, enables precise location monitoring and enhanced operational insights."}
                featureImage={gps_monitoring_image}
            />

            <FeaturesCardLtoR
                featureName={"Network Monitoring"}
                featureL1={"The IoT Platform seamlessly monitors LAN, WiFi, and Cellular networks, collecting real-time data from IoT devices across all communication mediums."}
                featureL2={"This ensures comprehensive network oversight and optimal device performance."}
                featureImage={network_monitoring_image}
            />

            <FeaturesCardRtoL
                featureName={"Remote Configuration"}
                featureL1={"Remote access to device settings and configurations from anywhere. "}
                featureL2={"Ability to make configuration changes in real-time without physical access to devices."}
                featureImage={remote_config_image_2}
            />
            <FeaturesCardLtoR
                featureName={"Security Features"}
                featureL1={"Role-based access control to restrict access to sensitive features and data. "}
                featureL2={"Encryption of communication channels to protect data in transit."}
                featureImage={security_image}
            />
            <FeaturesCardRtoL
                featureName={"Scalability"}
                featureL1={"Ability to scale up to manage large numbers of devices."}
                featureL2={"Support for heterogeneous device environments and protocols."}
                featureImage={scalability_image}
            />
            <FeaturesCardLtoR
                featureName={"Reporting and Analytics"}
                featureL1={"Comprehensive reporting on device performance, utilization, and compliance."}
                featureL2={"Data analytics tools for deriving insights from device data."}
                featureImage={reporting_analytics_image}
            />
        </div>
    )
}