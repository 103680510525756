import ExploreMoreBtn from "../CommonComponent/Buttons/ExploreMoreBtn.jsx"

const SupportedDevicesBigCard = () => (

    <div className="DeviceBigCard relative rounded-lg shadow-lg text-white  flex justify-center items-center">
        <img src="https://atreyo.in/sites/default/files/a-gallery-products/AG-207-01.png" className="xl:scale-125 lg:scale-125 md:scale-125 scale-100 transition ease-in-out duration-300" alt="AG-207_image" ></img>
        <div className=" absolute top-12 text-white text-center">
            <h2 className="text-7xl text-center">
                AG-207
            </h2>
            <p className="text-lg w-40 ml-12 mt-4 ">
                IoT Gateway
            </p>
        </div>

        <p className="absolute px-4 bottom-24 text-md py-2 text-center">
            Programmable OpenWRT
            LTE IoT gateway
            with RS485 and digital inputs.
        </p>

        <div className="absolute bottom-4 ">
            <ExploreMoreBtn DeviceLink={"https://www.atreyo.in/en/products/iot-gateways/openwrt-lte-gateway-rs485-AG-207"} />
        </div>
    </div>
);

// const SupportedDevicesBigCard = () => (
//     <div className="max-w-sm mx-auto  h-full DeviceSmallCard rounded-lg shadow-lg overflow-hidden ">
//         <div className="relative">
//             <img
//                 src={"https://atreyo.in/sites/default/files/a-gallery-products/AG-207-01.png"}
//                 alt={"AG-207"}
//                 className="w-full h-full object-cover object-top"
//             // style={{ mixBlendMode: 'multiply' }} // This ensures the image blends well with the gradient
//             />
//             <div className="absolute inset-0 flex flex-col justify-end p-4 text-white">
//                 <h2 className="text-7xl font-semibold mb-1">{"AG-207"}</h2>
//                 <h5 className="text-m font-semibold mb-1">{"IoT Gateway"}</h5>
//                 <p className="text-sm mb-4">
//                     Programmable OpenWRT
//                     LTE IoT gateway
//                     with RS485 and digital inputs.
//                 </p>

//                 <ExploreMoreBtn DeviceLink="https://www.atreyo.in/en/products/iot-gateways/openwrt-lte-gateway-rs485-AG-207" />
//             </div>
//         </div>
//     </div>
// );
export default SupportedDevicesBigCard;