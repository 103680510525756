import MoreBlogsBtn from "../CommonComponent/Buttons/MoreBlogsBtn.jsx"
import BlogCards from "./BlogCards.jsx"

export default function BlogPage() {
    return (
        <div className="mt-36">

            <div className="flex justify-center p-4 xl:pt-12 lg:pt-12 md:pt-12 pt:8">
                <h2 className="xl:text-5xl lg:text-5xl md:text-5xl text-4xl  text-slate-500 font-medium">Our Blogs</h2>
            </div>

            <BlogCards />

            <div className="ExploreMoreBlogs flex justify-center items-center mt-20">
                <MoreBlogsBtn />
            </div>
        </div>
    )
}