import CoreServiceCard from "./CoreServiceCard.jsx";


export default function CoreServicesPage() {
    return (

        <div className="CoreServicePage w-full h-auto flex flex-col justify-start px-8 mb-20" id="coreservices">
            <div className="mt-24">
                <div className="flex justify-center p-4 xl:py-12 lg:py-12 md:py-12 py:8">
                    <h2 className="xl:text-6xl lg:text-6xl md:text-5xl text-4xl  text-gray-500">Core Services</h2>
                </div>
            </div>
            <CoreServiceCard />
        </div>
    )
}