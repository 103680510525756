import FeatureCardCollection from "./FeaturesCardCollection.jsx";

export default function FeaturePage() {
    return (
        <div className="FeaturePage px-8 flex flex-col w-full " id="feature">
            <div className="flex justify-center p-4 xl:py-12 lg:py-12 md:py-12 py:8">
                <h2 className="xl:text-6xl lg:text-6xl md:text-5xl text-4xl  text-gray-500">{"Features"}</h2>
            </div>
            <FeatureCardCollection />
        </div>
    )
}