import linkedin from './FooterSectionIcons/linkedin.png'
import twitter from './FooterSectionIcons/twitter.png'
import facebook from './FooterSectionIcons/facebook.png'

export default function FooterSectionSocial() {
    return (
        <div className='flex flex-col xl:mt-0 lg:mt-0 md:mt-0 mt-6'>

            <div className="flex flex-row justify-center items-center">

                <a target="_blank" href='https://www.linkedin.com/company/atreyo/' className="h-8 w-8 mx-4">
                    <img src={linkedin} alt='linkedin'></img>
                </a>

                <a target="_blank" href='https://x.com/Atreyo_Research/' className="h-8 w-8 mx-4">
                    <img src={twitter} alt='twitter'></img>
                </a>

                <a target="_blank" href='https://www.facebook.com/atreyo.research/' className="h-8 w-8 mx-4">
                    <img src={facebook} alt='facebook'></img>
                </a>

            </div>

            <div className='flex justify-center mt-8'>
                <a target="_blank" href="https://atreyo.in/en" className="h-12 w-52 py-1">
                    <img src="https://www.atreyo.in/sites/default/files/a-icons/logo-r.png" alt="atreyo logo"></img>
                </a>
            </div>

        </div>
    )
}