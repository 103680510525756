// import { BrowserRouter, Routes, Route } from "react-router-dom";
import AtraRMS from "./AtraRMS.jsx";
// import FunctionMonitoringPage from "./Components/Function/Monitoring/FunctionMonitoringPage.jsx";
// import FunctionConfigurationPage from "./Components/Function/Configuration/FunctionConfigurationPage.jsx";
// import FunctionBackupPage from "./Components/Function/Backup/FunctionBackupPage.jsx";

function App() {
  return (
    <AtraRMS />
  );
}

export default App;
