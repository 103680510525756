import device_management_image from './device_management.image.png'
import device_monitoring_image from './device_monitoring_image.jpg'
import remote_config_image from './remote_config_image.png'

export default function CoreServiceCard() {
    return (
        <div className="flex justify-center items-center flex-wrap">

            <div className="CoreServiceCard bg-white hover:border-2 hover:border-teal-500 hover:scale-110  transition duration-300 ease-in-out rounded-md shadow-md hover:shadow-xl m-4 mx-6 cursor-pointer">
                <div className="w-full h-60 flex justify-center items-center ">
                    <img src={device_management_image} className='object-cover h-60 w-auto' alt="Device management illustration">
                    </img>
                </div>
                <div className="px-8">
                    <h3 className="text-2xl font-semibold text-gray-700 py-2">
                        Device <span className="CoreServiceTitle">Management</span>
                    </h3>
                    <ul className="text-gray-500 ">
                        <li className="py-1 ">
                            ATRA-RMS facilitates the <span className="text-teal-700 font-semibold bg-teal-100 rounded-md px-2">smooth onboarding</span> of IoT devices into the system.
                        </li>
                        <li className="py-1">
                            It allows users to configure settings and parameters for individual devices.
                        </li>
                    </ul>

                </div>
            </div>

            <div className="CoreServiceCard bg-white  hover:border-2 hover:border-teal-500 hover:scale-110  transition duration-300 ease-in-out rounded-md shadow-md hover:shadow-xl m-4 mx-6 cursor-pointer">
                <div className="w-full h-60 flex justify-center items-center">
                    <img src={device_monitoring_image} className='object-cover h-52 w-auto' alt="Device monitoring illustration">
                    </img>
                </div>
                <div className="px-8  overflow-auto">
                    <h3 className="text-2xl font-semibold text-gray-700 py-2">
                        Device <span className="CoreServiceTitle">Monitoring</span>
                    </h3>
                    <ul className="text-gray-500 ">
                        <li className="py-1">
                            ATRA-RMS provides <span className="text-teal-700 font-semibold bg-teal-100 rounded-md px-2">real-time monitoring</span> of device status, health, and performance.
                        </li>
                        <li className="py-1">
                            It conducts regular health checks to identify and address any issues or anomalies promptly.
                        </li>
                    </ul>

                </div>
            </div>

            <div className="CoreServiceCard bg-white  hover:border-2 hover:border-teal-500 hover:scale-110  transition duration-300 ease-in-out rounded-md shadow-md hover:shadow-xl m-4 mx-6 cursor-pointer">
                <div className="w-full h-60 flex justify-center items-center">
                    <img src={remote_config_image} className='object-cover h-60 w-auto' alt="Remote configuration illustartion">
                    </img>
                </div>
                <div className="px-8 overflow-auto">
                    <h3 className="text-2xl font-semibold text-gray-700 py-2">
                        Remote <span className="CoreServiceTitle">Configuration</span>
                    </h3>
                    <ul className="text-gray-500 ">
                        <li className="py-1">
                            Users can <span className="text-teal-700 font-semibold bg-teal-100  rounded-md px-2">remotely access and configure</span> IoT devices from a centralized dashboard.
                        </li>
                        <li className="py-1">
                            ATRA-RMS enables users to make configuration adjustments remotely, without requiring physical access to the devices.
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    )
}