import ReadMoreBtn from "../CommonComponent/Buttons/ReadMoreBtn.jsx"

export default function BlogCard() {
    return (
        <div className="flex flex-wrap justify-center">

            <div className="BlogCardOuter flex flex-col justify-end mx-6">
                <div className="outer rounded-2xl ">
                    <div className="BlogCard bg-cyan-900 rounded-2xl relative ">

                        <div className="BlogCardImage w-80 h-60 rounded-2xl flex justify-center items-center">
                            <img src="https://www.atreyo.in/sites/default/files/a-blog-images/Constrained_application%20Protoco%20view.png" className="object-cover h-full w-full rounded-2xl " alt="imagee"></img>
                        </div>

                        <div className="pt-60 px-4">
                            <h3 className="text-3xl text-white">
                                IoT Gateway to Cloud Protocols
                            </h3>
                            <p className="pt-2 text-slate-300">3 minutes read</p>
                            <p className="pt-4 text-slate-50">
                                In the rapidly evolving world of the Internet of Things (IoT), gateways play a crucial role in ensuring seamless communication between devices and cloud systems.</p>
                            <ul className="flex absolute bottom-14">
                                <li className="mx-1 px-2 bg-cyan-100 rounded  text-xs text-cyan-700 hover:text-cyan-900 hover:bg-cyan-200 hover:underline transition ease-in-out duration-300">
                                    <a href="https://www.atreyo.in/en/taxonomy/term/24">IoT</a>
                                </li>
                                <li className="mx-1 px-2 bg-cyan-100 rounded  text-xs text-cyan-700 hover:textcyanl-900 hover:bg-cyan-200 hover:underline transition ease-in-out duration-300">
                                    <a href="https://www.atreyo.in/en/taxonomy/term/27">IIoT</a>
                                </li>
                                <li className="mx-1 px-2 bg-cyan-100 rounded  text-xs text-cyan-700 hover:text-cyan-900 hover:bg-cyan-200 hover:underline transition ease-in-out duration-300">
                                    <a href="https://www.atreyo.in/en/taxonomy/term/76">Automation</a>
                                </li>
                            </ul>
                        </div>

                        <div className="absolute bottom-4 left-28 ">
                            <ReadMoreBtn BlogLink={"https://atreyo.in/en/resources/blog/iot_gateway_to_cloud_protocols"} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="BlogCardOuter flex flex-col justify-end mx-6">
                <div className="outer rounded-2xl ">
                    <div className="BlogCard bg-cyan-900 rounded-2xl relative">

                        <div className="BlogCardImage w-80 h-60 rounded-2xl flex justify-center items-center">
                            <img src="	https://www.atreyo.in/sites/default/files/a-blog-images/Industry%20needs%20an%20IoT%20view.png" className="object-cover h-full w-full rounded-2xl" alt="imagee"></img>
                        </div>

                        <div className="pt-60 px-4">
                            <h3 className="text-3xl text-white">
                                Why Every Industry Needs an IoT Gateway
                            </h3>
                            <p className="pt-2 text-slate-300">1 minute read</p>
                            <p className="pt-4 text-white">
                                The Internet of Things (IoT) revolution is transforming industries at an unprecedented pace. Sensors are embedded in everything from factory machines to agricultural equipment, generating a tidal wave of data.
                            </p>
                            <ul className="flex absolute bottom-14">
                                <li className="mx-1 px-2 bg-cyan-100 rounded text-xs text-cyan-700 hover:textcyanl-900 hover:bg-cyan-200 hover:underline transition ease-in-out duration-300">
                                    <a href="https://www.atreyo.in/en/taxonomy/term/27">IIoT</a>
                                </li>
                                <li className="mx-1 px-2 bg-cyan-100 rounded text-xs text-cyan-700 hover:textcyanl-900 hover:bg-cyan-200 hover:underline transition ease-in-out duration-300">
                                    <a href="https://www.atreyo.in/en/taxonomy/term/27">Industry</a>
                                </li>
                                <li className="mx-1 px-2 bg-cyan-100 rounded text-xs text-cyan-700 hover:text-cyan-900 hover:bg-cyan-200 hover:underline transition ease-in-out duration-300">
                                    <a href="https://www.atreyo.in/en/taxonomy/term/76">Automation</a>
                                </li>
                            </ul>
                        </div>

                        <div className="absolute bottom-4 left-28 ">
                            <ReadMoreBtn BlogLink={"https://atreyo.in/en/resources/blog/why_every_industry_needs_an_iot_gateway"} />
                        </div>

                    </div>
                </div>
            </div>

            <div className="BlogCardOuter flex flex-col justify-end mx-6">
                <div className="outer rounded-2xl ">
                    <div className="BlogCard g-cyan-900 rounded-2xl relative ">

                        <div className="BlogCardImage w-80 h-60 rounded-2xl flex justify-center items-center">
                            <img src="https://www.atreyo.in/sites/default/files/a-blog-images/Automatisation-view.png" className="object-cover h-full w-full rounded-2xl " alt="imagee"></img>
                        </div>

                        <div className="pt-60 px-4">
                            <h3 className="text-3xl text-white">
                                What are Automation Systems
                            </h3>
                            <p className="pt-2 text-slate-300">2 minutes read</p>
                            <p className="pt-4 text-white text-wrap truncate">
                                Automation is revolutionizing the way companies work, allowing them to streamline processes, reduce errors, and cut costs.
                            </p>
                            <ul className="flex absolute bottom-14">
                                <li className="mx-1 px-2 bg-cyan-100 rounded text-xs text-cyan-700 hover:text-cyan-900 hover:bg-cyan-200 hover:underline transition ease-in-out duration-300">
                                    <a href="https://www.atreyo.in/en/taxonomy/term/24">IoT</a>
                                </li>
                                <li className="mx-1 px-2 bg-cyan-100 rounded text-xs text-cyan-700 hover:textcyanl-900 hover:bg-cyan-200 hover:underline transition ease-in-out duration-300">
                                    <a href="https://www.atreyo.in/en/taxonomy/term/27">IIoT</a>
                                </li>
                                <li className="mx-1 px-2 bg-cyan-100 rounded text-xs text-cyan-700 hover:text-cyan-900 hover:bg-cyan-200 hover:underline transition ease-in-out duration-300">
                                    <a href="https://www.atreyo.in/en/taxonomy/term/76">Automation</a>
                                </li>
                            </ul>
                        </div>

                        <div className="absolute bottom-4 left-28 ">
                            <ReadMoreBtn BlogLink={"https://atreyo.in/en/resources/blog/what_are_automation_systems"} />
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}