import SupportedDevicesCardCollection from "./SupportedDevicesCardCollection.jsx"

export default function SupportedDevicesPage() {
    return (
        <div className="mt-16">
            <div className="flex justify-center p-4 xl:py-12 lg:py-12 md:py-12 py:8">
                <h2 className="xl:text-5xl lg:text-5xl md:text-5xl text-4xl  text-gray-500">Atra Supported Devices</h2>
            </div>
            <div className="mt-8 flex justify-center">
                <SupportedDevicesCardCollection />
            </div>
        </div>
    )
}