import Navigationbar from './Components/CommonComponent/Navigationbar.jsx'
import LandingPage from './Components/LandingPage/LandingPage.jsx';
import CoreServicesPage from './Components/CoreService/CoreServicePage.jsx';
import FeaturePage from './Components/Features/FeaturesPage.jsx';
// import PeriodicBackupPage from './Components/PeriodicBackup/PeriodicBackupPage.jsx';
// import HealthMonitoringPage from './Components/HealthMonitoring/HealthMonitoringPage.jsx';
// import FunctionPage from './Components/Function/FunctionPage.jsx';
// import SecurityPage from './Components/Security/SecurityPage.jsx';
import SupportedDevicesPage from './Components/SupportedDevices/SupportedDevicesPage.jsx'
import FooterSection from './Components/CommonComponent/FooterSection/FooterSection.jsx';
import BlogPage from './Components/Blog/BlogPage.jsx';

import './App.css';

export default function AtraRMS() {
    return (
        <div className=' w-full flex flex-col justify-center'>
            <Navigationbar />
            <LandingPage />
            <CoreServicesPage />
            <FeaturePage />
            {/* <PeriodicBackupPage /> */}
            {/* <HealthMonitoringPage /> */}
            {/* <FunctionPage /> */}
            {/* <SecurityPage /> */}
            <SupportedDevicesPage />
            <BlogPage />
            <FooterSection />
        </div>
    )
}