export default function FeaturesCardLtoR({ featureName, featureL1, featureL2, featureImage }) {
    return (
        <div className="FeaturesCardLtoR flex justify-between items-center">

            <div className="xl:w-3/4 lg:w-3/4 md:w-3/4 w-full flex flex-col justify-center pr-0 xl:pr-28 lg:pr-28 md:pr-16">
                <div className="h-16 w-16 ml-4 xl:hidden lg:hidden md:hidden block shadow-xl flex justify-center items-center rounded-lg ">
                    <img src={featureImage} alt='MonitoringImage' className=' h-12 w-12 '></img>
                </div>
                <h3 className="text-3xl font-semibold p-4 text-cyan-600">
                    {featureName}
                </h3>
                <p className="px-4 py-1 text-gray-500 ">
                    {featureL1}
                </p>
                <p className="px-4 py-1 text-gray-500 line-clamp-2">
                    {featureL2}
                </p>
            </div>

            <div className="xl:w-1/4 lg:w-1/4 md:w-1/4 w-0 hidden xl:block lg:block md:block flex justify-center items-center ">
                <div className="h-52 w-52 border-2 bg-gray-50 rounded-3xl shadow-xl flex justify-center items-center">
                    <img src={featureImage} alt='MonitoringImage' className=' h-40 w-40 rounded-3xl'></img>
                </div>
            </div>

        </div>
    )
}
