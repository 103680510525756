export default function LoginToDashboardbtn() {
    return (
        <a
            className="my-2 px-4 py-1 rounded-md text-white text-center w-48 h-8
                           bg-teal-500 hover:bg-teal-700  
                           shadow-sm shadow-gray-500/50
                           hover:shadow-md hover:shadow-gray-900/50
                           transition ease-in-out duration-300"
            id="dashBtn"
            href="https://atra.atreyo.io/login"
            target="_blank"
        >
            Login to Dashboard
        </a>
    )
}