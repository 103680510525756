import FooterSectionLogo from "./FooterSectionLogo"
import FooterSectionLinks from "./FooterSectionLinks"
import FooterSectionAbout from "./FooterSectionAbout"
import FooterSectionSocial from "./FooterSectionSocial"

export default function FooterSection() {
    return (
        <div className="Footer w-full xl:h-72 mt-0 py-16 border-t  flex xl:flex-row lg:flex-row md:flex-row flex-col justify-center items-center">

            <div className=" xl:w-1/4 lg:w-1/4 md:w-1/4 h-full ">
                <FooterSectionLogo />
            </div>
            <div className=" xl:w-1/4 lg:w-1/4 md:w-1/4 h-full ">
                <FooterSectionLinks />
            </div>
            <div className=" xl:w-1/4 lg:w-1/4 md:w-1/4 h-full ">
                <FooterSectionAbout />
            </div>
            <div className=" xl:w-1/4 lg:w-1/4 md:w-1/4 h-full ">
                <FooterSectionSocial />
            </div>

        </div>
    )
}