export default function MoreBlogsBtn() {
    return (
        <a class=" 
                    px-6 py-1  text-black group h-12 text-xl
                    w-fit inline-flex items-center gap-x-1 rounded-full 
                    bg-orange-500 hover:bg-orange-600
                    shadow-sm shadow-gray-900/50
                    hover:shadow-md hover:shadow-gray-900
                    transition ease-in-out duration-300"
            href="https://atreyo.in/en/resources/blog">
            More Blogs
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" className="lucide-icon lucide lucide-arrow-right group-hover:translate-x-[5px] ease-out duration-300"><path d="M5 12h14"></path><path d="m12 5 7 7-7 7"></path></svg>
        </a>
    )
}