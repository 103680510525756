export default function FooterSectionAbout() {
    return (
        <div className="flex flex-col justify-center xl:items-start lg:items-start md:items-start items-center ">
            <div className="flex flex-col underline text-gray-700">
                <a target="_blank" href="https://atreyo.in/en/about" className="hover:text-black pb-1 pr-12">About Us</a>
                <a target="_blank" href="https://atreyo.in/en/contact-us" className="hover:text-black py-1">Contact Us</a>
            </div>
            <div className="py-1 xl:mt-0 lg:mt-0 md:mt-0 mt-6">
                <p className="text-gray-600">
                    Address :
                </p>
                <p className="text-gray-700">
                    414, Sunrise Complex, Mansi Cross Road<br></br>
                    Ahmedabad, India<br></br>
                    +91 9727741417<br></br>
                    <a href="mailto:info@atreyo.in" className=" hover:text-black hover:underline">
                        info@atreyo.in
                    </a>
                </p>
            </div>
        </div>
    )
}